import React from "react"
import "./loanFlow.scss"
import { Container, useMediaQuery, useTheme } from "@material-ui/core"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/bundle"
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper"

import loanProcess1 from "../../assets/images/picture/flow-borrower-1.png"
import loanProcess2 from "../../assets/images/picture/flow-borrower-2.png"
import loanProcess3 from "../../assets/images/picture/flow-borrower-3.png"
import loanProcess4 from "../../assets/images/picture/flow-borrower-4.png"
import { useTranslation } from "react-i18next"
import Flip from "react-reveal/Flip"
import { StaticImage } from "gatsby-plugin-image"

function LoanFlow() {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("xs"))

  const flowData = [
    {
      imgUrl: loanProcess1,
      imgDesc: "home.how-loan-item-1",
    },
    {
      imgUrl: loanProcess2,
      imgDesc: "home.how-loan-item-2",
    },
    {
      imgUrl: loanProcess3,
      imgDesc: "home.how-loan-item-3",
    },
    {
      imgUrl: loanProcess4,
      imgDesc: "home.how-loan-item-4",
    },
  ]
  return (
    <Container className="loan-process">
      <Flip bottom delay={500}>
        <div className="section-title">
          <span className="title">{t(`home.how-loan-title`)}</span>
        </div>
      </Flip>
      <Swiper
        effect={"coverflow"}
        centeredSlides={true}
        loop={true}
        autoplay={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: isDesktop ? 100 : isMobileSmall ? 0 : 50,
          depth: 10,
          modifier: 2.5,
          scale: 0.9,
        }}
        pagination={{
          dynamicMainBullets: true,
          clickable: true,
        }}
        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
        slideToClickedSlide
      >
        {flowData.map((e, i) => (
          <SwiperSlide key={i}>
            <div className="process-img">
              <img src={e.imgUrl} alt={t("home.how-loan-item-1")} />
            </div>
            <h6>{(i + 1).toString() + `. ` + t(e.imgDesc)}</h6>
          </SwiperSlide>
        ))}
      </Swiper>
      <StaticImage
        src={"../../assets/images/decoration/we-grow-circle-red.png"}
        alt="circle"
        className="loan-circle-1"
      />
    </Container>
  )
}

export default LoanFlow
