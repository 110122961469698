import React, { useState } from "react"
import { withTrans } from "../../i18n/withTrans"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@material-ui/core"
import { t } from "i18next"
import { whistleblowingAccordionContent } from "../../constants/whistleblowingAccordionContent"
import {
  AddCircle,
  Email,
  EmailOutlined,
  RemoveCircle,
  WhatsApp,
} from "@material-ui/icons"
import "./whistleblowing.scss"

const WhistleBlowingSystemPage = () => {
  const [openedAccordion, setOpenedAccordion] = useState([])

  const handleClickAccordion = ({ index }) => {
    const tempOpennedAccordion = Object.assign([], openedAccordion)

    const itemIdx = tempOpennedAccordion.indexOf(index)
    if (itemIdx !== -1) {
      tempOpennedAccordion.splice(itemIdx, 1)
    } else {
      tempOpennedAccordion.push(index)
    }

    setOpenedAccordion(tempOpennedAccordion)
  }
  return (
    <Layout>
      <SEO title="Whistleblowing System" />

      <div className="content-whistleblowing">
        <div className="header-title">
          <div className="title">{t("whistleblower.title")}</div>
          <div
            className="desc"
            dangerouslySetInnerHTML={{ __html: t("whistleblower.desc") }}
          />
        </div>
        <div className="accordion-content">
          {whistleblowingAccordionContent.map((e, i) => (
            <Accordion
              title={t(e.header)}
              key={i}
              expanded={openedAccordion.includes(i)}
              square
              onClick={() => {
                handleClickAccordion({
                  index: i,
                })
              }}
              style={{ flexGrow: 1 }}
              className="accordion-item"
              elevation={0}
            >
              <AccordionSummary
                className={openedAccordion.includes(i) && "active"}
              >
                {openedAccordion.includes(i) ? (
                  <RemoveCircle color="inherit" />
                ) : (
                  <AddCircle color="inherit" />
                )}{" "}
                {t(e.header)}
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: t(e.content) }} />
                {e.contentDetail.map((detail, i) => (
                  <li dangerouslySetInnerHTML={{ __html: t(detail) }} key={i} />
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <div className="reporting-channel-card-content">
          <div className="channel-list">
            <div className="title">{t("whistleblower.report-form.title")}</div>
            <div>
              {t("whistleblower.report-form.desc-1").split("[split]")[0]}{" "}
              <span>
                <a
                  href="#"
                  className="channel-button"
                  onClick={async () => {
                    const url = `https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/official/j5Q0tmWRGDDT6OsTYs1Hqtz49tG4Ro6NczzVzcHifC4.docx`
                    const response = await fetch(url, { method: "GET" })
                    const blob = await response.blob()
                    const urlDownload = window.URL.createObjectURL(blob)
                    const link = document.createElement("a")

                    link.href = urlDownload
                    link.setAttribute(
                      "download",
                      "Form-pengaduan-wbs-asetku.docx"
                    )
                    link.click()
                  }}
                >
                  {t("whistleblower.report-form.form-report-here")}
                </a>
              </span>
              {t("whistleblower.report-form.desc-1").split("[split]")[1]}
            </div>

            <div className="button-list">
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=6287883334555&text=Hi%20Asetku%20Team%2C%0ASaya%20ingin%20melaporkan%20dugaan%20pelanggaran%20terkait....%0A%0ABerikut%20saya%20lampirkan%20dokumen%20Formulir%20Pengaduan."
                className="channel-button"
              >
                <WhatsApp /> WBS Asetku
              </a>
              <a
                target="_blank"
                href="mailto:wbs@asetku.com"
                className="channel-button"
              >
                <EmailOutlined /> wbs@asetku.com
              </a>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: t("whistleblower.report-form.desc-2"),
              }}
            />
          </div>
          <div className="report-channel-mascot" />
        </div>
      </div>
    </Layout>
  )
}

export default withTrans(WhistleBlowingSystemPage)
